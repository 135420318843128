import {Button, Image} from '@chakra-ui/react';
import {TOURMALINE_AUTH_ENDPOINT} from 'common/apiClient/constatns';
import {useTranslation} from 'next-i18next';

export const GoogleLoginButton = () => {
  const {t} = useTranslation('auth');
  return (
    <Button
      as="a"
      href={TOURMALINE_AUTH_ENDPOINT}
      background="white"
      color="color.regular"
      boxShadow="md"
      size="lg"
      gap="16px"
      leftIcon={
        <Image
          src="/images/g_logo.png"
          alt="google logo"
          w="18px"
          h="18px"
          rounded="md"
        />
      }
    >
      {t('Google でログイン')}
    </Button>
  );
};
