import {Stack, Card, Center, Image, Box} from '@chakra-ui/react';
import {staticPath} from 'lib/$path';
import {ReactNode} from 'react';
import {AppFooter} from './footer/AppFooter';

export const NotLoggedInLayout = ({children}: {children: ReactNode}) => {
  return (
    <Stack height="100vh">
      {/* App Bar */}
      <Card
        w="100%"
        flexDir="row"
        as="header"
        position="fixed"
        top="0"
        h="appBarHeight"
        alignItems="center"
        px={4}
        gap="4px"
        borderRadius="none"
        boxShadow="0 1px 1px 0 rgba(0, 0, 0, 0.1)"
        zIndex="2"
      >
        <Center flex="1">
          <Image
            src={staticPath.images.logo_with_text_on_right_svg}
            alt="Amethyst"
            height="40px"
          />
        </Center>
      </Card>
      {children}
      <Box flex={1} />
      <AppFooter />
    </Stack>
  );
};
