import {Alert, Box} from '@chakra-ui/react';
import {ReactNode} from 'react';

type Props = {
  children: ReactNode;
};

export const CommonAlert = ({children}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
      maxW="min(933px, 100%)"
    >
      <Alert
        rounded="sm"
        p={10}
        fontSize="text2"
        sx={{
          background: 'bgcolor.versatile',
          color: 'color.regular',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          whiteSpace: 'pre-wrap',
          gap: '10px',
        }}
      >
        {children}
      </Alert>
    </Box>
  );
};
