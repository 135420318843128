import {GoogleLoginButton} from './GoogleLoginButton';
import {useTranslation} from 'next-i18next';
import {CommonAlert} from 'common/CommonAlert';
import {Link, Stack, Text} from '@chakra-ui/react';
import {Env} from 'env/env';
import {MdOpenInNew} from 'react-icons/md';

export const GoogleLoginAlert = () => {
  const {t} = useTranslation('auth');

  return (
    <CommonAlert>
      <Stack spacing={4} alignItems="center" w="lg">
        <Text variant="text1">
          {t('Amethyst を利用するには、Google でログインしてください。')}
        </Text>

        <GoogleLoginButton />

        <Link
          href={Env.LP_URL + 'tos'}
          isExternal
          display="flex"
          alignItems="center"
          gap="4px"
          color="color.variation"
        >
          {t('Amethyst 利用規約')}
          <MdOpenInNew />
        </Link>
      </Stack>
    </CommonAlert>
  );
};
